import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { media } from '../utils/styles/media.js';

const StyledGallery = styled.ul.withConfig({
  componentId: "sg16120__sc-xv4pqe-0"
})(["display:flex;flex-wrap:wrap;list-style:none;margin:0;margin-top:-", ";margin-right:-", ";padding:0;", "{margin-top:-", ";margin-right:-", ";}"], tokens.space.sm, tokens.space.sm, media.md, tokens.space.md, tokens.space.md);
const StyledGalleryItem = styled.li.withConfig({
  componentId: "sg16120__sc-xv4pqe-1"
})(["flex:0 0 auto;position:relative;margin-right:", ";margin-top:", ";width:calc(33.333333% - ", ");", "{margin-right:", ";margin-top:", ";width:calc(33.333333% - ", ");}&::after{content:'';display:block;padding-bottom:100%;}"], tokens.space.sm, tokens.space.sm, tokens.space.sm, media.md, tokens.space.md, tokens.space.md, tokens.space.md);
const StyledThumbnailButton = styled.button.withConfig({
  componentId: "sg16120__sc-xv4pqe-2"
})(["background-color:transparent;background-position:50% 50%;background-repeat:no-repeat;background-size:cover;border:0;border-radius:1rem;bottom:0;left:0;padding:0;position:absolute;right:0;top:0;transition:box-shadow ", ";width:100%;&:focus{box-shadow:", ";outline:none;}"], tokens.transition.fast, tokens.shadow.focus);
const StyledGalleryBrowser = styled.div.withConfig({
  componentId: "sg16120__sc-xv4pqe-3"
})(["align-items:center;bottom:0;display:flex;flex-direction:column;height:100%;justify-content:center;left:0;overflow:hidden;padding:", ";position:absolute;right:0;top:0;", "{padding:", ";}"], tokens.space.md, media.sm, tokens.space.xl);
const StyledGalleryBrowserImageContainer = styled.div.withConfig({
  componentId: "sg16120__sc-xv4pqe-4"
})(["flex:1 1 auto;position:relative;width:100%;"]);
const StyledGalleryBrowserImageCenter = styled.div.withConfig({
  componentId: "sg16120__sc-xv4pqe-5"
})(["height:100%;left:50%;position:absolute;top:50%;transform:translate(-50%,-50%);width:100%;"]);
const StyledGalleryImage = styled.img.withConfig({
  componentId: "sg16120__sc-xv4pqe-6"
})(["box-shadow:", ";cursor:pointer;display:block;height:auto;left:50%;max-height:100%;max-width:100%;position:relative;top:50%;transform:translate(-50%,-50%);width:auto;"], tokens.shadow.modal);
const StyledGalleryBrowserButtons = styled.div.withConfig({
  componentId: "sg16120__sc-xv4pqe-7"
})(["align-items:center;display:flex;flex:0 0 auto;margin:", " 0;& > *:not(:last-child){margin-right:", ";}"], tokens.space.lg, tokens.space.xl);
const StyledSpinnerContainer = styled.div.withConfig({
  componentId: "sg16120__sc-xv4pqe-8"
})(["background-color:rgba(255,255,255,0.5);border-radius:", ";padding:", ";position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"], tokens.radius.circle, tokens.space.sm);

export { StyledGallery, StyledGalleryBrowser, StyledGalleryBrowserButtons, StyledGalleryBrowserImageCenter, StyledGalleryBrowserImageContainer, StyledGalleryImage, StyledGalleryItem, StyledSpinnerContainer, StyledThumbnailButton };
